<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <v-text-field
            v-model="filters.context"
            :label="'Contexte'"
            persistent-hint
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <v-text-field
            v-model="filters.id"
            :label="'Id'"
            persistent-hint
            outlined
            dense
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { debounce } from '@/helpers/debounce'

export default {
  name: 'TasksFilters',
  data() {
    return {
      filters: {
        context: this.$route.query.context,
        id: this.$route.query.id,
      },
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>
