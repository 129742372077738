<template>
  <Page>
    <template v-slot:content>
      <Filters
        @onFiltersChanged="onFiltersChanged"
      />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.date="{ item }">
            <div style="white-space: nowrap;">
              {{ item.created_at | date }}
              <v-tooltip
                v-if="item.started_at"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon medium>
                      mdi-timer-play
                    </v-icon>
                  </v-btn>
                </template>
                date de début: {{ item.created_at | date }}
              </v-tooltip>
              <v-tooltip
                v-if="item.ended_at"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon medium>
                      mdi-timer-stop
                    </v-icon>
                  </v-btn>
                </template>
                date de fin: {{ item.ended_at | date }}
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.command.params="{ item }">
            <div class="task-long-result">
              {{ item.command.params }}
            </div>
          </template>
          <template v-slot:item.result="{ item }">
            <div class="task-long-result">
              {{ item.error ?? item.result }}
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <StatusChip
              :status="item.status"
              :config="statusConfig"
            />
          </template>
        </v-data-table>
      </v-sheet>
    </template>
  </Page>
</template>

<script>
import Filters from '@/views/Admin/Tasks/Filters.vue'
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import StatusChip from '@/components/core/StatusChip.vue'

export default {
  name: 'TasksList',
  components: { Filters, Page, StatusChip },
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
        context: this.$route.query.context,
        id: this.$route.query.id,
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      table: {
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
        headers: [
          {
            text: this.$i18n.t('views.task.headers.date'),
            value: 'date',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.task.headers.context'),
            value: 'context',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.task.headers.command'),
            value: 'command.command',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.task.headers.params'),
            value: 'command.params',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.task.headers.result'),
            value: 'result',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.task.headers.status'),
            value: 'status',
            sortable: false,
          },
        ],
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortBy: ['created_at'],
          sortDesc: [true],
          multiSort: false,
        },
      },
      statusConfig: {
        error: {
          color: 'error',
          label: 'Erreur',
        },
        waiting: {
          color: 'waiting',
          label: 'En attente',
        },
        pending: {
          color: 'pending',
          label: 'En cours',
        },
        finished: {
          color: 'success',
          label: 'Terminé',
        },
      },
    }
  },
  computed: {
    queryString: function () {
      return 'tasks?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'AdminTasksList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'AdminTasksList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString, { cancelToken: axiosSource.token })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
  },
}
</script>

<style scoped>
.task-long-result {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  font-size: 10px;
}

.task-long-result:hover {
  cursor: pointer;
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
</style>
